import { FunctionComponent } from 'react';
import { IntlShape } from 'react-intl';

import { ControlProps, EditorProps, Property } from './controls-type';
import { RangePickerControl } from './range/range-picker-control';
import { TextControl } from './text-control';
import { PickListControl } from './picklist-control';
import { DatePickerControl } from './date-picker-control';
import { GeoshapePickerControl } from './geoshape-picker-control';
import { BooleanFieldControl2 } from './boolean-field-control2';
import { InputCharEditor, InputTextEditor, TextToText } from '../editors/input-text-editor';
import { DateTimeToText, DateToText, InputDateEditor, InputDateTimeEditor, TextToDate } from '../editors/input-date-editor';
import { InputNumberEditor, NumberToText, TextToNumber } from '../editors/input-number-editor';
import { GeoToText, InputGeolocationEditor } from '../editors/input-geolocation-editor';
import { PickListEditor } from '../editors/picklist-editor';
import { BooleanEditor, BooleanToText, TextToBoolean } from '../editors/boolean-editor';
import { DataType } from '../data-types';

export interface ControlType {
    elementType: FunctionComponent<ControlProps<any>>;
    paramType: 'specificValues' | 'range' | 'textValues' | 'dateRange' | 'geolocation';

    editorType: FunctionComponent<EditorProps<any>>;
    toText: (value: any, intl: IntlShape) => string | undefined;

    /**
     * Not backwards compatible with toText
     * Used to parse value stringifyed with toString
     */
    parseString: (valueAsString: string) => any;
}

interface TypeToControlType {
    [key: string]: ControlType | null;
}

const booleanFieldControlType: ControlType = {
    elementType: BooleanFieldControl2,
    editorType: BooleanEditor,
    paramType: 'specificValues',
    toText: BooleanToText,
    parseString: TextToBoolean,
};

const continuousNumberControlType: ControlType = {
    elementType: RangePickerControl,
    editorType: InputNumberEditor,
    paramType: 'range',
    toText: NumberToText,
    parseString: TextToNumber,
};

const numberControlType: ControlType = {
    elementType: PickListControl,
    editorType: InputNumberEditor,
    paramType: 'specificValues',
    toText: NumberToText,
    parseString: TextToNumber,
};

const fixedValuesNumberControlType: ControlType = {
    elementType: PickListControl,
    editorType: PickListEditor,
    paramType: 'specificValues',
    toText: NumberToText,
    parseString: TextToNumber,
};

const textControlType: ControlType = {
    elementType: TextControl,
    editorType: InputTextEditor,
    paramType: 'textValues',
    toText: TextToText,
    parseString: TextToText,
};

const charControlType: ControlType = {
    elementType: TextControl,
    editorType: InputCharEditor,
    paramType: 'textValues',
    toText: TextToText,
    parseString: TextToText,
};

const stringControlType: ControlType = {
    elementType: PickListControl,
    editorType: InputTextEditor,
    paramType: 'specificValues',
    toText: TextToText,
    parseString: TextToText,
};

const fixedValuesStringControlType: ControlType = {
    elementType: PickListControl,
    editorType: PickListEditor,
    paramType: 'specificValues',
    toText: TextToText,
    parseString: TextToText,
};

const dateControlType: ControlType = {
    elementType: DatePickerControl,
    editorType: InputDateEditor,
    paramType: 'dateRange',
    toText: DateToText,
    parseString: TextToDate,
};

const dateTimeControlType: ControlType = {
    elementType: DatePickerControl,
    editorType: InputDateTimeEditor,
    paramType: 'dateRange',
    toText: DateTimeToText,
    parseString: TextToDate,
};

const geolocationPickerControlType: ControlType = {
    elementType: GeoshapePickerControl,
    editorType: InputGeolocationEditor,
    paramType: 'geolocation',
    toText: GeoToText,
    parseString: TextToDate,
};

const typeToControlType: TypeToControlType = {
    'Bool': booleanFieldControlType,
    'Byte/continuous': continuousNumberControlType,
    'Short/continuous': continuousNumberControlType,
    'Int/continuous': continuousNumberControlType,
    'Long/continuous': continuousNumberControlType,
    'Float/continuous': continuousNumberControlType,
    'Double/continuous': continuousNumberControlType,
    'DateTime/continuous': dateTimeControlType,
    'String/fixedValues': fixedValuesStringControlType,
    'Text/fixedValues': fixedValuesStringControlType,
    'Email/fixedValues': fixedValuesStringControlType,
    'Url/fixedValues': fixedValuesStringControlType,
    'PhoneNumber/fixedValues': fixedValuesStringControlType,
    'Address/fixedValues': fixedValuesStringControlType,
    'Country/fixedValues': fixedValuesStringControlType,

    'Byte/fixedValues': fixedValuesNumberControlType,
    'Short/fixedValues': fixedValuesNumberControlType,
    'Int/fixedValues': fixedValuesNumberControlType,
    'Long/fixedValues': fixedValuesNumberControlType,
    'Float/fixedValues': fixedValuesNumberControlType,
    'Double/fixedValues': fixedValuesNumberControlType,

    'Byte': numberControlType,
    'Short': numberControlType,
    'Int': numberControlType,
    'Long': numberControlType,
    'Float': numberControlType,
    'Double': numberControlType,
    'MultiString': fixedValuesStringControlType,
    'String': stringControlType,
    'Text': textControlType,
    'Email': textControlType,
    'Url': textControlType,
    'PhoneNumber': textControlType,
    'Address': textControlType,
    'Country': stringControlType,
    'Char': charControlType,
    'Guid': textControlType,
    'DateTime': dateTimeControlType,
    'Date': dateControlType,
    'Geoshape': geolocationPickerControlType,
};

export const isPicklistControl = (property: Property): boolean => {
    const controlType = getControlType(property);
    if (controlType === stringControlType ||
        controlType === fixedValuesStringControlType ||
        controlType === booleanFieldControlType ||
        controlType === numberControlType ||
        controlType === fixedValuesNumberControlType) {
        return true;
    }

    return false;
};

/**
* If property type is UniversePropertyType use getVertexControlType instead
*/
export function getControlType(property: Property): ControlType | undefined {
    if (property.isContinuous) {
        const key = `${property.type}/continuous`;
        const type = typeToControlType[key];
        if (type === null) {
            return undefined;
        }
        if (type !== undefined) {
            return type || undefined;
        }
    }
    if (property.fixedValues) {
        const key = `${property.type}/fixedValues`;
        const type = typeToControlType[key];
        if (type === null) {
            return undefined;
        }
        if (type !== undefined) {
            return type || undefined;
        }
    }

    return typeToControlType[property.type] || undefined;
}

export function getBasicControlType(type: DataType): ControlType | null {
    return typeToControlType[type] ?? null;
}

/**
* If property type is MetaProperty from universe use getUniverseMetaPropertyControlType instead
*/
export function getMetaPropertyControlType(metaProperty: Property): ControlType | undefined {
    if (metaProperty.fixedValues) {
        const key = `${metaProperty.type}/fixedValues`;
        const type = typeToControlType[key];
        if (type === null) {
            return undefined;
        }
        if (type !== undefined) {
            return type || undefined;
        }
    }
    const key = metaProperty.type;

    return typeToControlType[key] || undefined;
}
