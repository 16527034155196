import { ReactNode, RefAttributes, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { isString } from 'lodash';

import { ArgInput, ArgInputCustomComponentProps, ArgInputDndConfig } from '../arg-input/arg-input';
import {
    ArgGetItemCheckedState,
    ArgGetItemClassName,
    ArgGetItemCount,
    ArgGetItemDisabled,
    ArgGetItemExpandState,
    ArgGetItemIcon,
    ArgGetItemKey,
    ArgGetItemLabel,
    ArgGetItemTooltip,
    computeItemClassName,
    computeItemKey,
    computeItemLabel,
    getDataTestIdFromProps,
} from '../utils';
import {
    ArgChangeReason,
    ArgInputState,
    ArgInputType,
    ArgMessageValues,
    ArgPlaceholderText,
    ArgRenderedText,
    ArgSize,
} from '../types';
import { DEFAULT_CARDINALITY, DEFAULT_SIZE } from '../defaults';
import { isMessageDescriptor } from '../utils/is-message-descriptor';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ProgressMonitor } from '../progress-monitors/progress-monitor';
import { ButtonClickEvent } from '../arg-button/arg-button';
import { TooltipPlacement } from '../arg-tooltip/utils';
import { isIn } from '../utils/is-in';
import { ArgComboMenu } from './arg-combo-menu';
import { ArgIconCheckboxStates } from '../arg-checkbox/arg-icon-checkbox';

import './arg-combo.less';

const multipleTagKey = '###MultipleTagKey####OO';

interface MultipleTag {
    key: string;
    label: string;
}

export type Cardinality = 'zeroMany' | 'optional' | 'one' | 'many'

export interface ArgComboProps<T> {
    id?: string;
    cardinality?: Cardinality;
    className?: ClassValue;
    hidden?: boolean;
    size?: ArgSize;
    type?: ArgInputType;
    state?: ArgInputState;
    left?: ReactNode | 'magnifier';
    right?: ReactNode | 'dropdown';

    progressMonitor?: ProgressMonitor;

    // T | T[] | undefined
    onChange?: (value: any, reason: ArgChangeReason, item?: T, forceSelection?: boolean) => void;
    onSearchInputChange?: (value?: string) => void;

    placeholder?: ArgPlaceholderText;
    disabled?: boolean;
    readOnly?: boolean;

    initialValue?: T | T[];
    value?: T | T[];

    clearable?: boolean;

    canCreate?: boolean;
    createLabel?: ArgRenderedText;
    onCreate?: () => void;

    messageValues?: ArgMessageValues;

    tooltip?: boolean | ArgRenderedText;
    tooltipPlacement?: TooltipPlacement;
    tooltipClassName?: ClassValue;

    items: T[] | (() => T[]);
    getItemLabel?: ArgGetItemLabel<T>;
    getItemKey?: ArgGetItemKey<T>;
    getItemCount?: ArgGetItemCount<T>;
    getItemClassName?: ArgGetItemClassName<T>;
    getItemDisabled?: ArgGetItemDisabled<T>;
    getItemIcon?: ArgGetItemIcon<T>;
    getItemTooltip?: ArgGetItemTooltip<T>;
    getItemCheckedState?: ArgGetItemCheckedState<T>;
    getItemExpandState?: ArgGetItemExpandState<T>;

    enableFilter?: boolean;

    topRender?: () => ReactNode;
    bottomRender?: () => ReactNode;
    renderItem?: (item: T, searchedToken?: string) => ReactNode;

    autoFocus?: boolean;

    popoverFitWidth?: boolean;
    popoverClassName?: ClassValue;

    renderInput?: (props: ArgInputCustomComponentProps<T>) => ReactNode;
    hideTags?: boolean;
    onPopoverClose?: (value?: T | T[]) => void;

    dndConfig?: ArgInputDndConfig;

    onItemExpand?: (item: T, isExpanded: boolean, event: ButtonClickEvent) => void;
    onItemCheck?: (item: T, checked: boolean, event: ButtonClickEvent) => void;

    allCheckButtonStates?: ArgIconCheckboxStates;
    onSelectAll?: (check: boolean, event: ButtonClickEvent) => void;
    enableSelectAllButton?: boolean;
}

export function ArgCombo<T>(props: (ArgComboProps<T> & RefAttributes<HTMLInputElement>)) {
    const {
        id,
        cardinality = DEFAULT_CARDINALITY,
        hidden,
        size = DEFAULT_SIZE,
        className,
        state,
        type,
        left,
        right = 'dropdown',
        placeholder,
        disabled,
        readOnly,
        value: externalValue,
        initialValue,
        onChange,
        tooltip,
        tooltipPlacement,
        tooltipClassName,
        getItemKey,
        getItemLabel,
        messageValues,
        clearable = true,
        autoFocus,
        progressMonitor,
        popoverFitWidth,
        popoverClassName,
        renderInput,
        getItemClassName,
        hideTags,
        onPopoverClose,
        dndConfig,
        canCreate,

    } = props;

    const intl = useIntl();
    const classNames = useClassNames('arg-combo');
    const [internalValue, setInternalValue] = useState<T | T[] | undefined>(initialValue);
    const [popoverVisible, setPopoverVisible] = useState<boolean>();

    const dataTestId = getDataTestIdFromProps(props);
    const useInternalValue = !isIn(props, 'value');
    const zeroOrOneSelection = (cardinality === 'one' || cardinality === 'optional');

    const valueArray = useMemo<{
        single: T;
        array: T[];
    } | undefined>(() => {
        const _value = (useInternalValue) ? internalValue : externalValue;

        if (Array.isArray(_value)) {
            if (!_value.length) {
                return undefined;
            }

            return {
                array: _value,
                single: _value[0],
            };
        }

        if (_value !== undefined) {
            return {
                array: [_value],
                single: _value,
            };
        }

        return undefined;
    }, [externalValue, useInternalValue, internalValue]);

    const handleTagClick = useCallback(() => {
        setPopoverVisible(true);
    }, [setPopoverVisible]);

    const handleTagClose = useCallback((tag: T | MultipleTag) => {
        if (!valueArray?.array.length) {
            return;
        }

        if ((tag as MultipleTag).key === multipleTagKey) {
            return;
        }

        const tagKey = computeItemKey(tag as T, getItemKey);
        const newSelection = valueArray.array.filter((t) => (computeItemKey(t, getItemKey) !== tagKey));

        setInternalValue(newSelection);
        onChange?.(newSelection, 'clear');
    }, [valueArray?.array, getItemKey, onChange]);

    const handleClear = useCallback(() => {
        setInternalValue([]);
        onChange?.([], 'clear');
    }, [onChange]);

    const cls = {
        [`cardinality-${cardinality}`]: true,
    };

    const handleParseValue = useCallback(() => {
        return null;
    }, []);

    const handleFormatLabel = useCallback((item: T | null) => {
        let label = computeItemLabel(item, getItemLabel);

        if (isMessageDescriptor(label)) {
            label = intl.formatMessage(label, messageValues);
        }

        if (isString(label)) {
            return label;
        }

        return '';
    }, [getItemLabel, intl, messageValues]);

    const value = (zeroOrOneSelection) ? valueArray?.single : undefined;

    const itemClassName = getItemClassName && (value !== undefined) && computeItemClassName(value, getItemClassName);

    const values = !zeroOrOneSelection ? valueArray?.array : undefined;

    const closePopover = useCallback(() => {
        setPopoverVisible(false);
    }, []);

    const handlePopoverVisibleChange = useCallback((visible: boolean) => {
        if (onPopoverClose && !visible) {
            onPopoverClose(value || values);
        }
        setPopoverVisible(visible);
    }, [onPopoverClose, value, values]);

    if (hidden) {
        return null;
    }

    return <ArgInput<T, T>
        id={id}
        size={size}
        state={state}
        type={type}
        data-testid={dataTestId}
        messageValues={messageValues}
        progressMonitor={progressMonitor}
        left={left}
        autoFocus={autoFocus}
        right={right}
        clearable={!readOnly && (!zeroOrOneSelection || cardinality === 'optional' && clearable)}
        readOnly={true}
        placeholder={(zeroOrOneSelection || !valueArray?.array.length) ? placeholder : undefined}
        disabled={disabled}
        value={value}
        parseValue={handleParseValue}
        formatValue={handleFormatLabel}
        tooltip={tooltip}
        tooltipClassName={tooltipClassName}
        tooltipPlacement={tooltipPlacement}
        popover={(
            <ArgComboMenu
                {...props}
                canCreate={canCreate}
                values={valueArray?.array}
                closePopover={closePopover}
                zeroOrOneSelection={zeroOrOneSelection}
                setInternalValue={setInternalValue}
            />
        )}
        popoverPlacement='bottomRight'
        popoverTrigger='click'
        popoverVisible={popoverVisible}
        popoverClassName={classNames('&-popover', popoverClassName)}
        popoverFitWidth={popoverFitWidth}
        onPopoverVisibleChange={handlePopoverVisibleChange}
        tags={hideTags ? undefined : values}
        getTagLabel={getItemLabel}
        getTagKey={getItemKey}
        tagToolip={false}
        tagReadOnly={readOnly}
        onClear={handleClear}
        onTagClick={handleTagClick}
        onTagClose={handleTagClose}
        className={classNames('&', className, cls, itemClassName)}
        renderInputComponent={renderInput}
        dndConfig={dndConfig}
    />;
//    {...dataProps}
}
