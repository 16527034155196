import React, { useCallback, useState } from 'react';
import { isFunction } from 'lodash';

import { ArgFilteredMenu } from '../arg-menu/arg-filtered-menu';
import type { ArgComboProps } from './arg-combo';
import { computeItemKey } from '../utils';
import { ArgComboMenuHeader } from './arg-combo-menu-header';

export interface ArgComboMenuProps<T> extends ArgComboProps<T> {
    values?: T[];
    closePopover: () => void;
    setInternalValue: React.Dispatch<React.SetStateAction<T | T[] | undefined>>;
    zeroOrOneSelection: boolean;
    enableSelectAllButton?: boolean;
}

export function ArgComboMenu<T>(props: ArgComboMenuProps<T>) {
    const {
        values,
        closePopover,
        setInternalValue,
        onChange,
        zeroOrOneSelection,
        getItemKey,
        items,
        canCreate,
        createLabel,
        onCreate,
        messageValues,
        bottomRender,
        renderItem,
        enableFilter,
        getItemIcon,
        getItemTooltip,
        getItemLabel,
        getItemDisabled,
        getItemCount,
        progressMonitor,
        getItemClassName,
        onSearchInputChange,
        onItemExpand,
        onItemCheck,
        getItemCheckedState,
        getItemExpandState,
    } = props;

    const [searchedToken, setSearchedToken] = useState<string>();

    const handleSelect = useCallback((item: T) => {
        if (zeroOrOneSelection) {
            closePopover();
            setInternalValue([item]);
            onChange?.(item, 'selection', item);

            return;
        }

        if (values) {
            const itemKey = computeItemKey(item, getItemKey);
            const idx = values.findIndex(value => computeItemKey(value, getItemKey) === itemKey);
            if (idx < 0) {
                const newSelection = [...values, item];
                setInternalValue(newSelection);
                onChange?.(newSelection, 'selection', item);

                return;
            }

            const newSelection = values.filter((i) => (itemKey !== computeItemKey(i, getItemKey)));

            setInternalValue(newSelection);
            onChange?.(newSelection, 'selection', item);

            return;
        }

        const newSelection = [item];
        setInternalValue(newSelection);
        onChange?.(newSelection, 'selection', item);
    }, [values, onChange, getItemKey, zeroOrOneSelection, closePopover, setInternalValue]);

    const handleOnCreate = useCallback(() => {
        closePopover();

        onCreate?.();
    }, [closePopover, onCreate]);

    const itemsList = isFunction(items) ? items() : items;

    return <ArgFilteredMenu<T>
        items={itemsList as T[]}
        canCreate={canCreate}
        createLabel={createLabel}
        onCreate={canCreate ? handleOnCreate : undefined}
        messageValues={messageValues}
        selected={values}
        topRender={() => (
            <ArgComboMenuHeader
                {...props}
                searchedToken={searchedToken}
                setSearchedToken={setSearchedToken}
            />
        )}
        bottomRender={bottomRender}
        renderItem={renderItem}
        onSelect={handleSelect}
        getItemKey={getItemKey}
        enableFilter={enableFilter && zeroOrOneSelection}
        searchedToken={searchedToken}
        getItemIcon={getItemIcon}
        getItemTooltip={getItemTooltip}
        getItemLabel={getItemLabel}
        getItemDisabled={getItemDisabled}
        showCheckbox={!zeroOrOneSelection}
        getItemCount={getItemCount}
        progressMonitor={progressMonitor}
        getItemClassName={getItemClassName}
        onSearchInputChange={onSearchInputChange}
        onItemExpand={onItemExpand}
        onItemCheck={onItemCheck}
        getItemCheckedState={getItemCheckedState}
        getItemExpandState={getItemExpandState}
    />;
}
